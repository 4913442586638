import React from 'react'
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import SolidHero from "../../components/solid-hero/solid-hero"
import ContactAndAddressTile from "../../components/contact-and-address-tile/contact-and-address-tile"
import AnmeldungCtaButton from "../../components/anmeldung-cta-button/anmeldung-cta-button"
import ClassOverview from "../../components/class-overview/class-overview"
import ClassOverviewItem from "../../components/class-overview/class-overview-item/class-overview-item"
import TheorieIntensivkursTermine from "../../components/theorie-intensivkurs-termine/theorie-intensivkurs-termine"
import AnmeldungSection from "../../components/anmeldung-section/anmeldung-section"

const KlasseA2Page = ({data}) => (
  <Layout>
    <SEO title="Klasse A2" description={'Mit deinem 18er soll auch dein Bike ein bisschen wachsen? Hol dir jetzt den A2-Motorradführerschein bei deiner Backnanger Fahrschule Rauscher.'}/>
    <SolidHero imageFluid={data.file.childImageSharp.fluid} imageAlt={'Motorradführerschein Klasse A2'} primary title={'Motorradführerschein'} subtitle={() => <>Klasse A2</>}></SolidHero>
    <AnmeldungCtaButton></AnmeldungCtaButton>
    <ClassOverview multiColumn text={"Man sagt zwar, mit 18 Jahren fängt der Ernst des Lebens an. Volle Geschäftsfähigkeit, Schicht- und Akkordarbeit mit über 40 Stunden und beispielsweise die Erlaubnis zur Eheschließung. Trotzdem (oder vielleicht gerade deshalb) kannst du, sobald du diese Jahreszahl erreicht hast, Freiheit noch einmal ganz neu kennenlernen: Mit dem A2er. Neben allem, was die A1-Fahrlizenz mit sich bringt, also normale 125er und die AM-Klasse, darfst du mit ihm auch die richtig fetten Maschinen fahren. Bis zu 200 km/h sowie maximal 35 kW sind so für dich drin – auch, wenn du bislang ganz ohne Führerschein bist. Das sorgt in Summe für jede Menge Abwechslung, Spaß und Unmengen an Adrenalin. Übrigens: Wenn du schon zwei Jahre erfolgreich mit dem A1er fährst, steht für dich nur noch eine praktische Prüfung an, die theoretische fällt in diesem Fall weg. Klingt gut? Dann (vegane) Lederkombi an und los!"}>
      <ClassOverviewItem title={'Ausbildung'}>
        <li>Mindestalter: 18 Jahre</li>
        <li>Ausbildung: Theorie und Praxis</li>
        <li>Prüfung: Theorieprüfung und praktische Prüfung</li>
        <li>Bei zweijährigem Vorbesitz der Klasse A1 nur praktische, aber keine theoretische Prüfung erforderlich.</li>
        <li>Eingeschlossene Klassen: AM, A1</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Stundenpensum'}>
        <li>Übungsstunden: nach Bedarf</li>
        <li>Überlandfahrt: 5</li>
        <li>Autobahnfahrt: 4</li>
        <li>Dunkelfahrt: 3</li>
        <li>Theorie/Doppelstunden á 90 Minuten bei Ersterteilung: 12 (bei Erweiterung nur 6)</li>
        <li>Zusatzstoff: 4</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Vorraussetzungen'}>
        <li>Biometrisches Passfoto</li>
        <li>Sehtest</li>
        <li>Erste-Hilfe-Kurs</li>
        <li>Personalausweis oder Reisepass mit Meldebestätigung</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Merkmale'}>
        <li>... Leistung max. 35 kW</li>
        <li>... Verhältnis Leistung/Gewicht max. 0,2 kW/kg</li>
      </ClassOverviewItem>

      <ClassOverviewItem title={'Aufstiegs Möglichkeiten'} linkPath={'https://fahrschulerauscher.de/pdf/informationen_klasse_a.pdf'} linkText={'Mehr erfahren'}>
        <li>Motorradführerscheine bieten oft noch Aufstiegsmöglichkeiten zu anderen Klassen. Weiter Infos dazu findest du hier.</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={'Weitere Infos'} linkPath={"https://tuvsud.com"} linkText={"Zur Website des TÜV"}>
        <li>Weitere Infos zu allen Führerscheinklassen findest du auf der Website des TÜV.</li>
      </ClassOverviewItem>

    </ClassOverview>
    <TheorieIntensivkursTermine/>
    <AnmeldungSection></AnmeldungSection>
    <ContactAndAddressTile></ContactAndAddressTile>
  </Layout>
)

export default KlasseA2Page

export const query = graphql`
  query KlasseA2PageQuery {
    file(relativePath: { eq: "klasse-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
